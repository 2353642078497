
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navbar from './marinaUi/navbar';
import { Footer } from './marinaUi/Footer';
import { AboutUs } from './marinaUi/Aboutus';
import { useState } from 'react';
import { Home } from './marinaUi/Home';

function App() {
  return (
    <div className="container-fluid p-0">
      <Navbar />
      <div className=' marginLeft'>
        <div className='col-12 d-flex justify-content-center flex-column align-items-center'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
          <Footer />
        </div>
      </div>





    </div>
  );
}

export default App;
