import React from 'react';


export const ImageGrid = () => {
    return (
        <div className='container-fluid d-flex justify-content-center'>
            <div className="image-grid image-grid2 col-9 marginUtility">
                <img
                    src='images/gridImg2.jpg'
                    alt=''

                />
                <img
                    src='images/gridimg.png'
                    alt='Sample'
                />
                <img
                    src='images/gridImg1.jpg'
                    alt=''

                />
                <img
                    src='images/rectangle5.png'
                    alt='Sample'
                />
                <img
                    src='images/fish.png'
                    alt='Sample'
                    className='imgbg5'
                />
                <img
                    src='images/rectangle7.png'
                    alt='Sample'
                />
            </div>
        </div>

    );
};

export default ImageGrid;
