import React from 'react'
import { ImageGrid } from './ImageGrid';
import { Ammenites } from './Ammenites';
import { BookingCards } from './BookingCards';
import { FishingCharter } from './FishingCharter';
import { ImageUi } from './imageUi';
import { Carousal } from './Carousal';
export const Home = () => {
    return (
        <div>
            <ImageUi />
            <Carousal />
            <ImageGrid />
            <Ammenites />
            <BookingCards  />
            <FishingCharter  />
        </div>
    )
}
