import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { assets } from './assets'; // Ensure you import your assets correctly

export const Carousal = () => {
  return (
    <div className='secondHeading d-flex flex-column justify-content-center align-items-center mb-4'>
      <div className='d-flex justify-content-center mb-4 my-4 container-fluid' id='ratesServices'>
        {assets.shipSvg}
        <h2 className="marina2 text-center mx-3">Our Marina</h2>
        {assets.shipSvg}
      </div>
      <div className="container-fluid my-4">
        <div className="Row utilitesFlex d-flex justify-content-center mr-1"> {/* Correct flex class */}
          <div className="col-12 col-sm-6 col-md-3 position-relative mb-3 d-flex justify-content-center align-items-center"> {/* Center content in column */}
            {assets.smallShipSvg && (
              <div className='svg-overlay text-center'>
                {assets.smallShipSvg}
                <div className='text'>
                  <h3>Better Amenities</h3>
                  <ul>
                    <li>Convenient Ship’s Store for all your boating and fishing needs</li>
                    <li>Deli Kitchen</li>
                    <li>Access to world-class shopping and dining nearby</li>
                    <li>Overnight lodgings nearby</li>
                    <li>Much more!</li>
                  </ul>
                </div>
              </div>
            )}
            <img src='images/forkknife.png' className='img-fluid' alt='Better Amenities' />
          </div>
          <div className="col-12 col-sm-6 col-md-3 position-relative mb-3 d-flex justify-content-center align-items-center"> {/* Adjusted column size and alignment */}
            {assets.smallShipSvg && (
              <div className='svg-overlay text-center'>
                {assets.smallShipSvg}
                <div className='text'>
                  <h3>Better Service</h3>
                </div>
              </div>
            )}
            <img src='images/image4.png' className='img-fluid' alt='Better Service' />
          </div>
          <div className="col-12 col-sm-6 col-md-3 position-relative mb-3 d-flex justify-content-center align-items-center">
            {assets.smallShipSvg && (
              <div className='svg-overlay text-center'>
                {assets.smallShipSvg}
                <div className='text'>
                  <h3>Smart Technology</h3>
                </div>
              </div>
            )}
            <img src='images/image6.png' className='img-fluid' alt='Smart Technology' />
          </div>
          <div className="col-12 col-sm-6 col-md-3 position-relative mb-3 d-flex justify-content-center align-items-center">
            {assets.smallShipSvg && (
              <div className='svg-overlay text-center'>
                {assets.smallShipSvg}
                <div className='text'>
                  <h3>Premier Location</h3>
                </div>
              </div>
            )}
            <img src='images/image3.png' className='img-fluid' alt='Premier Location' />
          </div>
        </div>
      </div>
    </div>
  );
};

