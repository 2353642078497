import React,{useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { assets } from './assets';
import { useLocation} from 'react-router-dom';

export const AboutUs = () => {
    const location = useLocation();
    const [length, setLength] = useState('');
    const [arrival, setArrival] = useState('');
    const [departure, setDeparture] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const state = location.state;
    const handleBooking = async () => {
        const apiUrl = 'http://localhost:5000/sendMail';
        const requestData = {
            length,
            arrival,
            departure,
            couponCode,
            field:'charter',
            email:"mishraritesh3156@gmail.com"
        };
       
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const data = await response.json();
                alert('Booking successful!');
                console.log(data); // Handle the response data as needed
            } else {
                alert('Booking failed!');
                console.error('Error:', response.statusText);
            }
        
    };
    console.log("state",state)
    return (
        <div className="container my-5">
            <div className="row d-flex justify-content-around ">
                {/* First Half */}
                <div className="col-12 col-md-6  aboutUsBorder p-4">
                    <h1 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px', }}>About </h1>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <p>{state && state.about}</p>
                    <p>{state && state.adress}</p>

                    <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>Services</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-6">
                            <div className="form-check d-flex align-items-center">
                                {assets.gas}
                                <label className="form-check-label" htmlFor="pumpPrice">
                                    Diesel
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check d-flex align-items-center">
                                {assets.gas}
                                <label className="form-check-label" htmlFor="amenities">
                                    Gas
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px', marginTop: '28px' }}>Listed Pump Prices</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row d-flex justify-content-center align-items-center mb-4">
                        <div className="col-6">
                            <div className="form-check ">
                                <h5>Super Gas: $5.10</h5>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check">
                                <h5>Diesel: $4.75</h5>
                            </div>
                        </div>
                    </div>

                    <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px', marginTop: '28px' }}>Amenities</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row d-flex justify-content-center align-items-center mb-4">
                        <div className="col-6">
                            <div className="form-check d-flex align-items-center">
                                {assets.iceBlack}
                                <label className="form-check-label" htmlFor="pumpPrice">
                                    Ice
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check d-flex align-items-center">
                                {assets.iceBlack}
                                <label className="form-check-label" htmlFor="pumpPrice">
                                    Ice
                                </label>
                            </div>
                        </div>
                    </div>

                    <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px', marginTop: '28px' }}>Info</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row d-flex justify-content-center align-items-center mb-4">
                        <div className="col-12">
                            <div className="form-check justify-content-start">
                                <h5>Cancellation Policy -48 Hours Policy</h5>
                            </div>
                        </div>

                    </div>


                    <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>Photos</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="row">
                        {[1, 2, 3, 4].map((_, index) => (
                            <div key={index} className="col-6 col-md-3 mb-4">
                                <div className="skeleton" style={{ width: '100%', height: '150px', backgroundColor: '#f0f0f0', borderRadius: '5px' }}></div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Second Half */}
                <div className="col-12 col-md-5 aboutUsBorder py-4 mt-2" id="specific">
                    <h2 className="text-primary" style={{ fontFamily: 'Manrope', fontSize: '24px' }}>Reservation</h2>
                    <hr className="bg-light" style={{ border: '1px solid #e0e0e0' }} />
                    <div className="d-flex mb-3">
                        <button className="btn btn-primary me-2">Short Term</button>
                        <button className="btn btn-secondary">Season/Annual</button>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-md-4">
                            <label htmlFor="length" className="form-label">Length</label>
                            <input type="text" className="form-control" id="length"  onChange={(e) => setLength(e.target.value)}/>
                        </div>
                        <div className="col-12 col-md-4">
                            <label htmlFor="arrival" className="form-label">Arrival</label>
                            <input type="date" className="form-control" id="arrival"  onChange={(e) => setArrival(e.target.value)}/>
                        </div>
                        <div className="col-12 col-md-4">
                            <label htmlFor="departure" className="form-label">Departure</label>
                            <input type="date" className="form-control" id="departure"  onChange={(e) => setDeparture(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 ">
                            <label htmlFor="length" className="form-label">Have a coupon code?</label>
                            <input type="text" className="form-control" id="length"  onChange={(e) => setCouponCode(e.target.value)}/>
                        </div>
                    </div>
                    <button className="btn-new mt-4 col-12" onClick={handleBooking}>
                        Book Now
                        {assets.shipsteer}
                    </button>

                </div>
            </div>
        </div>
    );
};
