import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelectorAll('.other').forEach(element => {
      element.addEventListener('click', () => {
        navigate('/');
      });
    });
  }, [navigate]);

  return (
    <nav className="navbar navbar-expand-lg navbarStyles mb-4 sticky-top bg-white container-fluid" style={{ overflow: "hidden" }}>
      <div className="container-fluid d-flex justify-content-between">
        <a className="logoImg p-0 m-0 nav-t nav-item other" style={{ fontWeight: "900px" }} href="#">
          {<img src="images/Logo.svg" style={{ height: "8rem", width: "8rem" }}></img>}
        </a>
        <div className='d-flex flex-column  align-items-center'>
          <div className='d-flex  align-items-center'>

            <a className="navbar-brand brandName p-0 m-0 nav-t nav-item other" style={{ fontWeight: "900px" }} href="#">
              Toler's Cove Marina LLC
            </a>
            <button className="navbar-toggler nav-t" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className='d-flex ml-2 navimg'>
            <div className='col-7 d-flex justify-content-between pr-1 ml-1' style={{ "marginLeft": "16px" }}>
              <img src='/assets/ICS_Tango.svg 1.png' width="12px" height="12px" style={{ "marginRight": "16px" }} />
              <img src='/assets/ICS_Oscar.svg 1.png' width="12px" height="12px" style={{ "marginLeft": "8px" }} />
              <img src='/assets/ICS_Lima.svg 1.png' width="12px" height="12px" style={{ "marginLeft": "4px" }} />
              <img src='/assets/ICS_Echo.svg 1.png' width="12px" height="12px" style={{ "marginLeft": "4px" }} />
              <img src='/assets/ICS_Romeo.svg 1.png' width="12px" height="12px" style={{ "marginLeft": "4px" }} />
              <img src='/assets/ICS_Sierra.svg 1.png' width="12px" height="12px" style={{ "marginLeft": "4px" }} />
            </div>
            <div className='col-4 d-flex justify-content-between pl-1 ' style={{ "marginLeft": "16px" }}>
              <img src='/assets/ICS_Charlie.svg 1.png' width="12px" height="12px" style={{ "marginLeft": "4px" }} />
              <img src='/assets/ICS_Oscar.svg 1.png' width="12px" height="12px" style={{ "marginLeft": "4px" }} />
              <img src='/assets/ICS_Victor.svg 1.png' width="12px" height="12px" style={{ "marginLeft": "px" }} />
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse col-12 col-lg-7" id="navbarNav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <li className="nav-item other">
              <a className="nav-link navFontColor" aria-current="page" href="#">Our Marina</a>
            </li>
            <li className="nav-item other">
              <a className="nav-link navFontColor" href="#charters">Boat Rentals</a>
            </li>
            <li className="nav-item other">
              <a className="nav-link navFontColor" href="#ratesServices">Rates & Services</a>
            </li>
            <li className="nav-item other">
              <a className="nav-link navFontColor" href="#resources">Resources</a>
            </li>
            <li className="nav-item other">
              <a className="nav-link navFontColor" href="#footer">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

