import React from 'react';
import { assets } from './assets';
import { ImageCard } from './imageCard';

export const FishingCharter = () => {
  const captainMap = [{
    name: "Captian Addison Rupert",
    images: ["images/cap1.png", "images/Addison2.jpg", "images/Addison3.jpg", "images/Addison4.jpg", "images/Addison5.jpg"],
    about: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    adress:""
  }, {
    name: "John Townsend Cooper",
    images: ["images/cap2.png", "images/johnCooper2.jpg", "images/johnCooper3.jpg", "images/johnCooper4.jpg", "images/johnCooper5.jpg", "images/johnCooper6.jpg", "images/johnCooper7.jpg", "images/johnCooper8.jpg", "images/johnCooper9.jpg"],
    about: "Gray Bay Outdoors, LLC offers inshore and nearshore fishing trips, from 5 to 8 hours, year-round for a variety of fish species, including flounder, spottail bass, speckled trout, sheepshead, and black drum, among others. Most trips are limited to 4 passengers, however, 5 can be accommodated under certain circumstances. Pricing varies based upon the number of passengers and the length of the trip",
    adress:"John Townsend Cooper Gray Bay Outdoors, LLC 843.906.7320 Inshore and Nearshore Fishing Charters"
  }, {
    name: "Captain Shane Sinclair",
    images: ["images/cap3.png", "images/shane2.jpg", "images/shane3.jpg", "images/shane4.jpg", "images/shane5.jpg", "images/shane6.jpg", "images/shane7.jpg", "images/shane8.jpg", "images/shane9.jpg"],
    about: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    adress:""
  }]
  return (
    <div className='container-fluid charter marginUtility ' id='charters' style={{ marginTop: "4rem" }}>
      <div className='d-flex justify-content-center mb-4'>
        {assets.shipSvg}
        <h2 className="marina2">Fishing Charters</h2>
        {assets.shipSvg}
      </div>
      <div className='Row d-flex justify-content-around'>
        {
          captainMap.map((item, ind) => {
            return <ImageCard capDetails={item} key={item.name} />
          })
        }
      </div>
    </div>
  );
}
