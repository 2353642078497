import React from 'react';
import { assets } from './assets'; 
import { useNavigate } from 'react-router-dom';

export const BookingCards = () => {
  const navigate = useNavigate();
  return (
    <div className="Row d-flex justify-content-around container-fluid my-4 mb-4">
      <div className="d-flex flex-column col-12 col-md-6 col-lg-3 bookingCards p-4 align-items-center mb-4">
        <div className="svg-container mb-3">{assets.shipSvg}</div>
        <h2 className="marina">Transient</h2>
        <div className="col-12 bg1 d-flex flex-column align-items-center p-1">
          <p className="text-white h5 mb-1">Starting at:</p>
          <p className="text-white h3 mb-1">NIGHTLY</p>
          <p className="text-white h5 mb-1">Per Ft./ Day</p>
        </div>
        <div className="d-flex justify-content-center my-4 text-primary h5">
          <p>30' LOA Minimum</p>
        </div>
        <div className="Row col-12 d-flex justify-content-around">
          <div className="col-12 col-sm-4 d-flex flex-column align-items-center label-box mb-3">
            <div className="col-12 d-flex justify-content-around">
              <label htmlFor="option1" className="label">30AMP</label>
              <input type="radio" id="option1" name="fav_language" value="Option1" />
            </div>
            <div className="col-12 label-desc">
              <p>$10.00/day</p>
            </div>
          </div>
          <div className="col-12 col-sm-4 d-flex flex-column align-items-center label-box mb-3">
            <div className="col-12 d-flex justify-content-around">
              <label htmlFor="option2" className="label">50AMP</label>
              <input type="radio" id="option2" name="fav_language" value="Option2" />
            </div>
            <div className="col-12 label-desc">
              <p>$25.00/day</p>
            </div>
          </div>
          <div className="col-12 col-sm-4 d-flex flex-column align-items-center label-box mb-3">
            <div className="col-12 d-flex justify-content-around">
              <label htmlFor="option3" className="label">100AMP</label>
              <input type="radio" id="option3" name="fav_language" value="Option3" />
            </div>
            <div className="col-12 label-desc">
              <p>$30.00/day</p>
            </div>
          </div>
        </div>
        <button className="btn-new mt-4 col-11" onClick={() => {navigate('/about')}}>
          Book Now
          {assets.avg}
        </button>
      </div>
      <div className="d-flex flex-column col-12 col-md-6 col-lg-3 bookingCards p-4 align-items-center justify-content-center mb-4">
        <div className="svg-container mb-3">{assets.shipSvg}</div>
        <h2 className="marina">TBD</h2>
      </div>
      <div className="d-flex flex-column col-12 col-md-6 col-lg-3 bookingCards p-4 align-items-center justify-content-center mb-4">
        <div className="svg-container mb-3">{assets.shipSvg}</div>
        <h2 className="marina">TBD</h2>
      </div>
    </div>
  );
};

